<template>
  <div>
    <div class="row preview--text mb-1">
      <div class="col-6">
        {{ $t('addListing.discount.totalcut') }}
      </div>
      <div class="col-6">
        {{ $n(this.discountedValue, 'currency', 'en-SG') }}
      </div>
    </div>
    <div class="row preview--text mt-0">
      <div class="col-6">
        {{ discountedPriceTitle ? discountedPriceTitle : $t('addListing.discount.finalPrice') }}
      </div>
      <div class="col-6">
        <b>{{ $n(this.discountedPrice, 'currency', 'en-SG') }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'discount-preview',
  props: {
    realPrice: {
      type: String,
      default: null,
    },
    discountedPriceTitle: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState({
      discountPercentage: state => state.v2.listingForm.sect1.basicInfo.discountPercentage,
      discountPrice: state => state.v2.listingForm.sect1.basicInfo.discountPrice,
    }),
    discountedValue() {
      if (this.discountPercentage) {
        return (this.realPrice * this.discountPercentage) / 100;
      } else if (this.discountPrice) {
        if (this.discountPrice >= this.realPrice - 1) {
          return this.realPrice - 1;
        } else {
          return this.discountPrice;
        }
      } else {
        return null;
      }
    },
    discountedPrice() {
      if (this.discountedValue) {
        return this.realPrice - this.discountedValue;
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preview--text {
  //font-size: $font-sm;
}
</style>
